<script setup>
import { onMounted } from "vue";
import { setPageTitle } from "../../utils/pageTitle";
import BillingOrders from "./BillingOrders.vue"

onMounted(() => {
  setPageTitle("Billing Orders");
});

</script>

<template>
  <div id="orders-grid-wrapper">
    <BillingOrders />
  </div>
</template>

<style>
#orders-grid-wrapper {
  height: calc(100vh - 49px);
  overflow: hidden;
}
</style>
